import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import {
  useForgetPasswordMutation,
  useLoginMutation,
  useUpdatePasswordMutation,
} from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice";
import { toast } from "react-toastify";

const ForgetScreen = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const [isOtp, setIsOtp] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [forget, { isLoading: isLoadingForget }] = useForgetPasswordMutation();
  const [update, { isLoading: isLoadingUpdate }] = useUpdatePasswordMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await forget({ email }).unwrap();
      setIsOtp(true);
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  const updateHandler = async (e) => {
    e.preventDefault();
    try {
      if (password !== repassword) {
        toast.error("Password not matching");
        return;
      }

      const res = await update({ otp, password, email }).unwrap();
      navigate("/login");
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  return (
    <FormContainer>
      <h1 style={{ color: "black" }}>Forget Password</h1>

      {isOtp ? (
        <Form onSubmit={updateHandler}>
          <Form.Group controlId="otp" className="my-3">
            <Form.Label>OTP</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="password" className="my-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="email" className="my-3">
            <Form.Label>Re-enter Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Re-enter password"
              value={repassword}
              onChange={(e) => setRepassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button
            type="submit"
            variant="primary"
            className="mt-2 bg-black"
            style={{ color: "white" }}
            disabled={isLoadingUpdate}
          >
            Update Password
          </Button>
          {isLoadingUpdate && <Loader />}
        </Form>
      ) : (
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="email" className="my-3">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button
            type="submit"
            variant="primary"
            className="mt-2 bg-black"
            style={{ color: "white" }}
            disabled={isLoadingForget}
          >
            Continue
          </Button>
          {isLoadingForget && <Loader />}
        </Form>
      )}
    </FormContainer>
  );
};

export default ForgetScreen;
