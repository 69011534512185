import React from "react";
import "./collection-item.styles.scss";
import CustomButton from "./CustomButton";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const CollectionItem = ({ item }) => {
  const navigate = useNavigate();
  const { name, price, image } = item;
  return (
    <div
      onClick={() => {
        navigate(`/product/${item._id}`);
      }}
      className="collection-item"
      style={{ cursor: "pointer" }}
    >
      {/* <div
        className="image"
        style={{
          backgroundImage: `url(${image[0]})`,
          position: "relative",
        }}
      /> */}
      <img className="image" src={image[0]} style={{ position: "relative" }} />
      {item.stock <= 0 && (
        <span
          style={{
            position: "absolute",
            top: "5%",
            left: "5%",
            border: "white solid 1px",
            padding: "3px",
            backgroundColor: "#ededed",
          }}
        >
          Sold out
        </span>
      )}
      <div>
        <span className="name">{name}</span>
      </div>
      <div>
        <span className="price">{`$${price}`}</span>
      </div>
      {/* <div>
        <Button style={{ backgroundColor: "black", color: "white" }}>
          Add to cart
        </Button>
      </div> */}
    </div>
  );
};

export default CollectionItem;
