import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
// import Header from "./components/Header1";
import Footer from "./components/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Marquee from "react-fast-marquee";
import { useState } from "react";
import { useGetSettingQuery } from "./slices/productsApiSlice";
import ScrollToTop from "react-scroll-to-top";

function App() {
  const [showSlider, setShowSlider] = useState(true);
  const { data: setting } = useGetSettingQuery();
  return (
    <>
      {showSlider && (
        <div
          style={{
            display: "flex",
            position: "sticky",
            top: "0",
            color: "white",
            background: "linear-gradient(to right, #1a2a6c, #b21f1f, #ac7f1f)",
            zIndex: "9",
          }}
        >
          <Marquee>{setting?.setting?.label}</Marquee>
          <button
            style={{
              border: "none",
              background: "none",
              marginRight: "5px",
              padding: "2px",
              fontWeight: "700",
            }}
            onClick={() => setShowSlider(false)}
          >
            x
          </button>
        </div>
      )}
      <Header showSlider={showSlider} />
      <main className="py-3">
        <Container>
          <Outlet />
        </Container>
      </main>
      <Footer />
      <ToastContainer />
      <ScrollToTop smooth />
    </>
  );
}

export default App;
