import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Product from '../components/Product'
import { useGetProductsQuery } from '../slices/productsApiSlice';
import { useParams } from 'react-router-dom';
import FilterIcon from '../assets/filter.png'

const AdditionScreen = () => {
    const { pageNumber, keyword } = useParams();
    const { data, isLoading, error } = useGetProductsQuery({
        keyword,
        pageNumber,
    });
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span style={{ color: 'black', fontSize: '32px', fontWeight: '600' }}>New additions</span>
                <img style={{ height: '20px', width: '20px', cursor: "pointer" }} src={FilterIcon} />
            </div>
            <Row>
                {data?.products?.map((product) => (
                    <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                        <Product product={product} />
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default AdditionScreen