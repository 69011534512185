import { Row, Col, Button, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProductsQuery } from "../slices/productsApiSlice";
import { Link } from "react-router-dom";
import Product from "../components/Product";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Paginate from "../components/Paginate";
import ProductCarousel from "../components/ProductCarousel";
import Meta from "../components/Meta";
import CollectionPreview from "../components/CollectionPreview";
import "./collection.styles.scss";
import CollectionItem from "../components/CollectionItem";

const HomeScreen = () => {
  const navigate = useNavigate();
  const { pageNumber, keyword } = useParams();

  const { data, isLoading, error } = useGetProductsQuery({
    isBestSeller: true,
  });

  console.log("window.innerWidth =====", window.innerWidth);

  const width = window.innerWidth / 400;
  console.log("width =====", width);
  let colsString = "";

  for (let i = 0; i < parseInt(width) || 0; i += 1) {
    colsString += "1fr ";
  }

  console.log("colsString =====", colsString);

  return (
    <>
      {!keyword ? (
        <div className="py-3">{<ProductCarousel />}</div>
      ) : (
        <Link to={-1} className="btn btn-light mb-4">
          Go Back
        </Link>
      )}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Meta />
          <h1 style={{ color: "black" }}>Best Sellers</h1>

          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {data.products.map((product, id) => {
              console.log("product =====", product);
              return <CollectionPreview key={id} product={product} />;
            })}
          </div> */}

          <div className="collection-page">
            <div className="items" style={{ gridTemplateColumns: colsString }}>
              {data.products.map((product, id) => (
                <CollectionItem kkey={id} item={product} />
              ))}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{ backgroundColor: "black", color: "white" }}
              onClick={() => navigate("/shop?type=Best Sellers")}
            >
              View all
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default HomeScreen;
