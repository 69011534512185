import React from "react";
import { Col, Row } from "react-bootstrap";
import Product from "../components/Product";
import { useGetProductsQuery } from "../slices/productsApiSlice";
import { Link, useParams } from "react-router-dom";
import FilterIcon from "../assets/filter.png";
import DressIcon from "../assets/resin.jpg";
import Category from "../components/Category";

const categories = [
  { id: 1, name: "Home Decore", img: DressIcon },
  { id: 2, name: "Accessories", img: DressIcon },
  { id: 3, name: "Kids Specials", img: DressIcon },
];

const ClothingScreen = () => {
  const { pageNumber, keyword } = useParams();
  //   const { data, isLoading, error } = useGetProductsQuery({
  //     keyword,
  //     pageNumber,
  //   });
  return (
    <>
      <Link style={{ color: "black" }} className="btn btn-light my-3" to={-1}>
        Go Back
      </Link>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            color: "black",
            fontSize: "32px",
            fontWeight: "600",
            textAlign: "center",
            marginBottom: "15px",
          }}
        >
          Resin Art
        </div>
        <div>
          <Row className="justify-content-md-center">
            <Col md="auto" className="my-2">
              <Category product={categories[0]} />
            </Col>
            <Col md="auto" className="my-2">
              <Category product={categories[1]} />
            </Col>
            <Col md="auto" className="my-2">
              <Category product={categories[2]} />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ClothingScreen;
