import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  useGetSettingQuery,
  useUpdateSettingMutation,
} from "../../slices/productsApiSlice";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

const SettingScreen = () => {
  const navigate = useNavigate();
  const [currSetting, setCurrSetting] = useState("");

  const { data: setting, refetch } = useGetSettingQuery();
  const [updateSettings, { isLoading: loadingUpdate }] =
    useUpdateSettingMutation();

  const updateSetting = async (e) => {
    e.preventDefault();
    try {
      await updateSettings({
        label: currSetting?.label,
        about: currSetting?.about,
        description: currSetting?.description,
        address: currSetting?.address,
        email: currSetting?.email,
        phone: currSetting?.phone,
      }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      toast.success("Settings updated");
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  console.log("currSetting =====", currSetting);

  useEffect(() => {
    setCurrSetting({
      label: setting?.setting?.label,
      about: setting?.setting?.about,
      description: setting?.setting?.description,
      address: setting?.setting?.address,
      email: setting?.setting?.email,
      phone: setting?.setting?.phone,
    });
    console.log("setting 12 =====", setting);
  }, [setting]);

  return (
    <>
      <div style={{ display: "flex" }}>
        <div
          style={{
            padding: "10px 5px",
            border: "1px solid black",
            borderRadius: "3px",
            cursor: "pointer",
            margin: "5px",
          }}
          onClick={() => navigate("/admin/orderlist")}
        >
          Orders
        </div>
        <div
          style={{
            padding: "10px 5px",
            border: "1px solid black",
            borderRadius: "3px",
            cursor: "pointer",
            margin: "5px",
          }}
          onClick={() => navigate("/admin/productlist")}
        >
          Products
        </div>
        <div
          style={{
            padding: "10px 5px",
            border: "1px solid black",
            borderRadius: "3px",
            cursor: "pointer",
            margin: "5px",
          }}
          onClick={() => navigate("/admin/userlist")}
        >
          Users
        </div>
        <div
          style={{
            padding: "10px 5px",
            border: "1px solid black",
            borderRadius: "3px",
            cursor: "pointer",
            margin: "5px",
          }}
          onClick={() => navigate("/admin/setting")}
        >
          Settings
        </div>
      </div>
      {loadingUpdate && <Loader />}
      <div style={{ marginTop: "20px" }}>
        <Form.Group controlId="name">
          <Form.Label>Ribbon Label</Form.Label>
          <Form.Control
            type="label"
            placeholder="Enter label"
            value={currSetting?.label}
            onChange={(e) =>
              setCurrSetting((state) => ({ ...state, label: e.target.value }))
            }
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="name">
          <Form.Label>About Us Title</Form.Label>
          <Form.Control
            type="label"
            placeholder="Enter title"
            value={currSetting?.about}
            onChange={(e) =>
              setCurrSetting((state) => ({ ...state, about: e.target.value }))
            }
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="name">
          <Form.Label>About Us Description</Form.Label>
          <Form.Control
            type="label"
            placeholder="Enter description"
            value={currSetting?.description}
            onChange={(e) =>
              setCurrSetting((state) => ({
                ...state,
                description: e.target.value,
              }))
            }
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="name">
          <Form.Label>Address</Form.Label>
          <Form.Control
            type="label"
            placeholder="Enter address"
            value={currSetting?.address}
            onChange={(e) =>
              setCurrSetting((state) => ({ ...state, address: e.target.value }))
            }
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="name">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="label"
            placeholder="Enter email"
            value={currSetting?.email}
            onChange={(e) =>
              setCurrSetting((state) => ({ ...state, email: e.target.value }))
            }
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="name">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            type="label"
            placeholder="Enter phone"
            value={currSetting?.phone}
            onChange={(e) =>
              setCurrSetting((state) => ({ ...state, phone: e.target.value }))
            }
          ></Form.Control>
        </Form.Group>
        <Button
          type="submit"
          onClick={(e) => updateSetting(e)}
          variant="primary"
          style={{ marginTop: "1rem" }}
        >
          Update
        </Button>
      </div>
    </>
  );
};

export default SettingScreen;
