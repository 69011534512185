// import { Card } from "react-bootstrap";
// import { Link, useNavigate } from "react-router-dom";
// import "./Product.css";
// import Rating from "./Rating";

// const Product = ({ product }) => {
//   const navigate = useNavigate();
//   return (
//     <Card
//       className="my-3 p-3 rounded"
//       style={{ cursor: "pointer" }}
//       onClick={() => navigate(`/product/${product._id}`)}
//     >
//       <div
//         className="imageZoom"
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <div
//           style={{
//             minHeight: "300px",
//             maxHeight: "300px",
//           }}
//         >
//           <img
//             style={{
//               objectFit: "cover",
//               maxHeight: "300px",
//               maxWidth: "300px",
//             }}
//             src={product.image}
//             variant="top"
//           />
//         </div>
//       </div>

//       <div>
//         <div>{product.name}</div>
//       </div>

//       <Card.Text as="h3">${product.price}</Card.Text>
//     </Card>
//   );
// };

// export default Product;

import { Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./Product.css";
import Rating from "./Rating";

const Product = ({ product }) => {
  const navigate = useNavigate();
  return (
    <Card
      className="my-3 p-3 rounded"
      style={{ cursor: "pointer" }}
      onClick={() => navigate(`/product/${product._id}`)}
    >
      <div
        className="imageZoom"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <div
          style={{
            minHeight: "300px",
            maxHeight: "300px",
          }}
        > */}
        <img
          style={{
            objectFit: "cover",
            maxHeight: "500px",
            width: "400px",
          }}
          src={product.image[0]}
          variant="tops"
        />
        {/* </div> */}
      </div>

      <div>
        <div>{product.name}</div>
      </div>

      <Card.Text as="h3">${product.price}</Card.Text>
    </Card>
  );
};

export default Product;
