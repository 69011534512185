import { useState, useEffect, KeyboardEventHandler } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button, Table } from "react-bootstrap";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import FormContainer from "../../components/FormContainer";
import { toast } from "react-toastify";
import {
  useGetProductDetailsQuery,
  useUpdateProductMutation,
  useUploadProductImageMutation,
} from "../../slices/productsApiSlice";
import CreatableSelect from "react-select/creatable";
import { FaTrash } from "react-icons/fa";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

const clothingCategory = [
  { name: "Tops", value: "tops" },
  { name: "Bottoms", value: "bottoms" },
  { name: "Dresses", value: "dresses" },
  { name: "Bralette", value: "bralette" },
  { name: "Shorts and Skirt", value: "shorts" },
  { name: "Cover Ups", value: "covers" },
];

const resinCategory = [
  { name: "Home Decore", value: "homeDecore" },
  { name: "Accessories", value: "accessories" },
  { name: "Kids Special", value: "kids" },
];

const decoreCategory = [
  { name: "Costers", value: "costers" },
  { name: "Wall Art", value: "wall_art" },
  { name: "Jars", value: "jars" },
  { name: "Show Pieces", value: "pieces" },
  { name: "Candle Holders", value: "candle" },
  { name: "Incense Holders", value: "incense" },
];

const accessoriesCategory = [
  { name: "Earrings", value: "earrings" },
  { name: "Jewellery Dish", value: "jewellery" },
];

const kidsCategory = [
  { name: "Shells", value: "shells" },
  { name: "Key Chains", value: "keyChains" },
];

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const ProductEditScreen = () => {
  const { id: productId } = useParams();

  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState([]);
  const [colors, setColors] = useState([]);
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [image, setImage] = useState([]);
  const [brand, setBrand] = useState("clothing");
  const [category, setCategory] = useState("tops");
  const [subcategory, setSubcategory] = useState("costers");
  const [description, setDescription] = useState("");
  const [isBestSeller, setIsBestSeller] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [stock, setStock] = useState(0);

  const options = [
    { id: "S", label: "S" },
    { id: "M", label: "M" },
    { id: "L", label: "L" },
    { id: "XL", label: "XL" },
    { id: "XLL", label: "XLL" },
    { id: "One Size", label: "One Size" },
  ];

  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana",
  ].sort();

  const {
    data: product,
    isLoading,
    refetch,
    error,
  } = useGetProductDetailsQuery(productId);

  const [updateProduct, { isLoading: loadingUpdate }] =
    useUpdateProductMutation();

  const [uploadProductImage, { isLoading: loadingUpload }] =
    useUploadProductImageMutation();

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await updateProduct({
        productId,
        name,
        price,
        image,
        brand,
        stock,
        category,
        description,
        subcategory,
        isBestSeller,
        tags: tags.map((tag) => tag.value),
        colors: colors.map((tag) => tag.value),
        sizes: selectedOptions,
      }).unwrap(); // NOTE: here we need to unwrap the Promise to catch any rejection in our catch block
      toast.success("Product updated");
      refetch();
      navigate("/admin/productlist");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (product) {
      setName(product.name);
      setPrice(product.price);
      setImage(product.image);
      setBrand(product.brand);
      setCategory(product.category);
      setSubcategory(product.subcategory);
      setDescription(product.description);
      setIsBestSeller(product.isBestSeller);
      setTags(product.tags.map((tag) => createOption(tag)));
      setSubcategory(product.subcategory);
      setStock(product.stock);
    }
  }, [product]);

  const uploadFileHandler = async (e) => {
    const files = e.target.files;

    for (let i = 0; i < files?.length; i += 1) {
      const curr = files[i];
      const formData = new FormData();
      formData.append("file", curr);
      try {
        const res = await uploadProductImage(formData).unwrap();
        toast.success(res.message);
        setImage((state) => [...state, res.url]);
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setTags((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  const handleKeyDownColor = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setColors((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionChange = (event) => {
    const optionId = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedOptions([...selectedOptions, optionId]);
    } else {
      setSelectedOptions(selectedOptions.filter((id) => id !== optionId));
    }
  };

  return (
    <>
      <Link to="/admin/productlist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Product</h1>
        {loadingUpdate && <Loader />}
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error.data.message}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="price">
              <Form.Label>Stock</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter price"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="image">
              <Form.Label>Image</Form.Label>
              <Form.Control
                label="Choose File"
                multiple
                onChange={uploadFileHandler}
                type="file"
              ></Form.Control>
              <div>
                {image?.map((data, index) => (
                  <Table striped bordered hover responsive className="table-sm">
                    <tr key={index}>
                      <td>
                        <img style={{ width: "60px" }} src={data} />
                      </td>
                      <td>{data?.split("/")?.pop()}</td>
                      <td>
                        <Button
                          variant="danger"
                          className="btn-sm"
                          onClick={() => {
                            const newArr = JSON.parse(JSON.stringify(image));
                            newArr.splice(index, 1);
                            setImage(newArr);
                          }}
                        >
                          <FaTrash style={{ color: "black" }} />
                        </Button>
                      </td>
                    </tr>
                  </Table>
                  // <div
                  //   style={{ display: "flex", justifyContent: "space-between" }}
                  // >
                  //
                  //   <div>{data?.split("/")?.pop()}</div>
                  //   <div
                  //     style={{ color: "red", cursor: "pointer" }}
                  //     onClick={() => {
                  //       const newArr = JSON.parse(JSON.stringify(image));
                  //       newArr.splice(index, 1);
                  //       setImage(newArr);
                  //     }}
                  //   >
                  //     X
                  //   </div>
                  // </div>
                ))}
              </div>
              {loadingUpload && <Loader />}
            </Form.Group>

            <Form.Group style={{ marginTop: "15px" }} controlId="brand">
              <Form.Label>Division</Form.Label>
              <Form.Select
                onChange={(e) => setBrand(e.target.value)}
                value={brand}
              >
                <option value="clothing">Clothing</option>
                <option value="resin_art">Resin Art</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="category">
              <Form.Label>Category</Form.Label>
              <Form.Select
                onChange={(e) => setCategory(e.target.value)}
                value={category}
              >
                {(brand === "clothing" ? clothingCategory : resinCategory).map(
                  (cat) => (
                    <option value={cat.value}>{cat.name}</option>
                  )
                )}
              </Form.Select>
            </Form.Group>

            {brand === "resin_art" && (
              <Form.Group controlId="subcategory">
                <Form.Label>Subcategory</Form.Label>
                <Form.Select
                  onChange={(e) => setSubcategory(e.target.value)}
                  value={subcategory}
                >
                  {(category === "homeDecore"
                    ? decoreCategory
                    : category === "accessories"
                    ? accessoriesCategory
                    : kidsCategory
                  ).map((sub) => (
                    <option value={sub.value}>{sub.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}

            {brand === "clothing" ? (
              <Form.Group controlId="size">
                <Form.Label>Size</Form.Label>
                <div className={`dropdown ${isOpen ? "show" : ""}`}>
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="multiSelectDropdown"
                    onClick={toggleDropdown}
                  >
                    Select size
                  </button>
                  <div
                    style={{ width: "20%" }}
                    className={`dropdown-menu ${isOpen ? "show" : ""}`}
                    aria-labelledby="multiSelectDropdown"
                  >
                    {options.map((option) => (
                      <Form.Check
                        style={{ marginLeft: "10%" }}
                        key={option.id}
                        type="checkbox"
                        id={`option_${option.id}`}
                        label={option.label}
                        checked={selectedOptions.includes(option.id)}
                        onChange={handleOptionChange}
                        value={option.id}
                      />
                    ))}
                  </div>
                </div>
              </Form.Group>
            ) : (
              <Form.Group controlId="Colors">
                <Form.Label>Colors</Form.Label>
                <CreatableSelect
                  components={components}
                  inputValue={inputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={(newValue) => setColors(newValue)}
                  onInputChange={(newValue) => setInputValue(newValue)}
                  onKeyDown={handleKeyDownColor}
                  placeholder="Type something and press enter..."
                  value={colors}
                />
              </Form.Group>
            )}

            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <SunEditor
                plugin=""
                setContents={description}
                onChange={(e) => setDescription(e)}
                setOptions={{
                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize"],
                    // ['paragraphStyle', 'blockquote'],
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                    ],
                    ["fontColor", "hiliteColor"],
                    ["align", "list", "lineHeight"],
                    ["outdent", "indent"],

                    ["table", "horizontalRule", "link"],
                    // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
                    // ['imageGallery'], // You must add the "imageGalleryUrl".
                    // ["fullScreen", "showBlocks", "codeView"],
                    ["preview"],
                    ["removeFormat"],

                    // ['save', 'template'],
                    // '/', Line break
                  ], // Or Array of button list, eg. [['font', 'align'], ['image']]
                  defaultTag: "div",
                  minHeight: "300px",
                  showPathLabel: false,
                  font: sortedFontOptions,
                }}
              />
            </Form.Group>

            <Form.Group controlId="tags">
              <Form.Label>Tags</Form.Label>
              <CreatableSelect
                components={components}
                inputValue={inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={(newValue) => setTags(newValue)}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                placeholder="Type something and press enter..."
                value={tags}
              />
            </Form.Group>

            <Form.Group
              style={{ marginTop: "5px" }}
              onClick={() => setIsBestSeller((state) => !state)}
            >
              <input
                type="checkbox"
                checked={isBestSeller}
                // onChange={(e) => setIsBestSeller(e.target.checked)}
                style={{ marginRight: "5px" }}
              />
              <Form.Label>Is a best seller?</Form.Label>
            </Form.Group>

            <Button
              type="submit"
              variant="primary"
              style={{ marginTop: "1rem" }}
            >
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default ProductEditScreen;
