import React, { useState } from "react";
import FacebookIcon from "../assets/facebook.png";
import InstagramIcon from "../assets/instagram.png";
import "./ContactScreen.css";
import Faq from "react-faq-component";
import axios from "axios";
import { BASE_URL } from "../constants";
import { toast } from "react-toastify";
import LocationIcon from "../assets/location.png";
import PhoneIcon from "../assets/phone.png";
import EmailIcon from "../assets/email.png";
import { useGetSettingQuery } from "../slices/productsApiSlice";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

let data = {
  rows: [
    {
      title: "Do you have a storefront I can visit?",
      content:
        "We currently operate exclusively online, but you can also find us at local markets, where you can experience our products in person. Stay updated on our whereabouts through our social media. Our online platform offers a convenient way to explore our range. If you have any questions or need assistance, feel free to reach out to us.",
    },
    {
      title: "Where are your product designed?",
      content:
        "Our products are designed right here in New Zealand by our designer Drashti Patel.",
    },
    {
      title: "How do I return or exchange any items?",
      content:
        "Follow the return and exchange process here. Please ensure you meet the criteria to return your parcel. Thank you!",
    },
    {
      title: "Can I return my sale items?",
      content:
        "Please choose wisely, unfortunately, all sale items are for final sale and we won't be able to return and exchange or credit them for you. Appreciate it.",
    },
    {
      title: "What does one size mean?",
      content:
        "The clothing item is designed to fit a range of sizes, providing more flexible and adaptable fit.",
    },
    {
      title: "I don't know my size!",
      content:
        "Certainly! To determine your clothing size, visit size guide. Measure your bust, waist and hips, then compare the measurements to the size chart provided. If you need further assistance, feel free to reach out to our contact us.",
    },
    {
      title: "I just order online, when should I recieve my order?",
      content:
        "We strive to pack your order within 2 working days and you'll receive a notification once your parcel is on its way. Ordrs placed on weekends or public holidays will be processed on the first working day. If for any reason, we can't meet this sameday dispatch target, we'll contact you to inform you of any delays. Please allow on additional 1-2 days for rural delivery.",
    },
    {
      title: "How long should my refund take to process?",
      content:
        "If your package is returned within 7-day return window, we aim to process your refund or exchange note swiftly, typically within 1-2 business days. YOu will recieve an email notification at the address listed on your account once this transaction is complete. Please be aware that your bank may necessitate extra time to process and post the transaction to your account after receiving the necessary information. Kindly allow up to 2-3 working days for this process.",
    },
  ],
};

const ContactScreen = () => {
  const { data: setting, refetch } = useGetSettingQuery();
  console.log("setting =====", setting);
  const [isError, setIsError] = useState(false);
  const [form, setForm] = useState({
    name: "",
    message: "",
    email: "",
    phone: "",
  });

  data.rows = data?.rows?.map((row) => {
    return {
      title: row.title,
      content: `<div style='margin-left: 4px; margin-right: 20px; margin-bottom: 20px; margin-top: 20px;'>${row.content}</div>`,
    };
  });

  console.log("data =====", data);

  const handleSend = () => {
    const isValidEmail = validateEmail(form?.email);

    if (!isValidEmail) {
      setIsError(true);
      return;
    }

    axios.post(`${BASE_URL}/api/email/contact`, form).then((res) => {
      toast.success(res?.data?.message);
      setForm({
        name: "",
        message: "",
        email: "",
        phone: "",
      });
    });
  };

  return (
    <div class="containr" style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <div class="form">
          <div class="contact-info">
            <h3 class="title">{setting?.setting?.about}</h3>
            <p class="text">{setting?.setting?.description}</p>

            <div class="info">
              <div class="information">
                <img src={LocationIcon} class="icon" alt="" />{" "}
                {/* how do i import icons? */}
                <p style={{ marginBottom: "0px" }}>
                  {setting?.setting?.address}
                </p>
              </div>
              <div class="information">
                <img class="icon" src={EmailIcon} alt="" />{" "}
                {/* how do i import icons? */}
                <p style={{ marginBottom: "0px" }}>{setting?.setting?.email}</p>
              </div>
              <div class="information">
                <img src={PhoneIcon} class="icon" alt="" />{" "}
                {/* how do i import icons? */}
                <p style={{ marginBottom: "0px" }}>{setting?.setting?.phone}</p>
              </div>
            </div>

            <div class="social-media">
              <p>Connect with us :</p>
              <div>
                <a
                  href="https://instagram.com/fashstash.by.drashti"
                  target="_blank"
                >
                  <img
                    src={FacebookIcon}
                    style={{ height: "30px", width: "30px", margin: "5px" }}
                  />
                </a>
                <a href="https://www.facebook.com/fashstash13" target="_blank">
                  <img
                    src={InstagramIcon}
                    style={{ height: "30px", width: "30px", margin: "5px" }}
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="contact-form">
            <form>
              <h3 class="title">Contact us</h3>
              <div class="input-containr">
                <input
                  onChange={(e) =>
                    setForm((state) => ({ ...state, name: e.target.value }))
                  }
                  type="text"
                  name="name"
                  class="input"
                />
                {form?.name?.length <= 0 && <label for="">Name</label>}
              </div>
              <div class="input-containr">
                <input
                  onChange={(e) => {
                    setIsError(false);
                    setForm((state) => ({ ...state, email: e.target.value }));
                  }}
                  type="email"
                  name="email"
                  class={isError ? "input-error" : "input"}
                />
                {form?.email?.length <= 0 && <label for="">Email</label>}
              </div>
              <div class="input-containr">
                <input
                  onChange={(e) =>
                    setForm((state) => ({ ...state, phone: e.target.value }))
                  }
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  name="phone"
                  class="input"
                />
                {form?.phone?.length <= 0 && <label for="">Phone</label>}
              </div>
              <div class="input-containr textarea">
                <textarea
                  onChange={(e) =>
                    setForm((state) => ({ ...state, message: e.target.value }))
                  }
                  name="message"
                  class="input"
                ></textarea>
                {form?.message?.length <= 0 && <label for="">Message</label>}
              </div>
              <input onClick={() => handleSend()} value="Send" class="btn" />
            </form>
          </div>
        </div>
      </div>
      <div style={{ width: "80%", marginTop: "100px" }}>
        <h3
          style={{ color: "black", fontSize: "30px", textAlign: "center" }}
          class="title"
        >
          Frequently Asked Questions
        </h3>
        <div style={{ marginTop: "30px" }}>
          <Faq
            data={data}
            styles={{
              bgColor: "white",
              // titleTextColor: "#48482a",
              // rowTitleColor: "#78789a",
              rowTitleTextSize: "18px",

              // rowContentColor: "#48484a",
              // rowContentTextSize: "16px",
              // rowContentPaddingTop: "10px",
              // rowContentPaddingBottom: "10px",
              // rowContentPaddingLeft: "50px",
              // rowContentPaddingRight: "150px",
              // arrowColor: "black",
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default ContactScreen;
