import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import Paginate from "../../components/Paginate";
import {
  useGetProductsQuery,
  useDeleteProductMutation,
  useCreateProductMutation,
} from "../../slices/productsApiSlice";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import ArrowIcon from "../../assets/up-arrow.png";

const clothingCategory = {
  tops: "Tops",
  bottoms: "Bottoms",
  dresses: "Dresses",
  bralette: "Bralette",
  shorts: "Shorts and Skirt",
  covers: "Cover Ups",
};

const resinCategory = {
  homeDecore: "Home Decore",
  accessories: "Accessories",
  kids: "Kids Special",
};

const subcategory = {
  costers: "Costers",
  wall_art: "Wall Art",
  jars: "Jars",
  pieces: "Show Pieces",
  candle: "Candle Holders",
  incense: "Incense Holders",
  earrings: "Earrings",
  jewellery: "Jewellery Dish",
  shells: "Shells",
  keyChains: "Key Chains",
};

const ProductListScreen = () => {
  const { pageNumber } = useParams();
  const navigate = useNavigate();
  const [divProducts, setDivProducts] = useState([]);
  const [currSort, setCurrSort] = useState("name");
  const [currCategory, setCurrCategory] = useState("clothing");
  const [sort, setSort] = useState([
    { field: "name", dir: "asc" },
    { field: "price", dir: "asc" },
    { field: "stock", dir: "asc" },
  ]);

  const { data, isLoading, error, refetch } = useGetProductsQuery({
    pageNumber,
  });

  console.log("data =====", data);

  useEffect(() => {
    if (data) {
      let filtered = data?.products?.filter(
        (product) => product.brand === "clothing"
      );

      filtered = filtered?.sort((a, b) => {
        if (a[currSort] < b[currSort]) {
          return -1;
        }
        if (a[currSort] > b[currSort]) {
          return 1;
        }
        return 0;
      });

      setDivProducts(filtered);
    }
  }, [data]);

  const [deleteProduct, { isLoading: loadingDelete }] =
    useDeleteProductMutation();

  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure")) {
      try {
        await deleteProduct(id);
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const [createProduct, { isLoading: loadingCreate }] =
    useCreateProductMutation();

  const createProductHandler = async () => {
    if (window.confirm("Are you sure you want to create a new product?")) {
      try {
        await createProduct();
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const getCategory = (brand, category) => {
    if (brand === "clothing") {
      return clothingCategory[category];
    } else {
      return resinCategory[category];
    }
  };

  const handleDivision = (div) => {
    setCurrCategory(div);
    setCurrSort("name");
    setSort([
      { field: "name", dir: "asc" },
      { field: "price", dir: "asc" },
      { field: "stock", dir: "asc" },
    ]);
    let filtered = data?.products?.filter((product) => product.brand === div);

    filtered = filtered?.sort((a, b) => {
      if (a[currSort] < b[currSort]) {
        return -1;
      }
      if (a[currSort] > b[currSort]) {
        return 1;
      }
      return 0;
    });

    setDivProducts(filtered);
  };

  const handleSort = (dir) => {
    let sorted = divProducts;

    sorted = sorted?.sort((a, b) => {
      if (a[currSort] < b[currSort]) {
        return dir === "desc" ? -1 : 1;
      }
      if (a[currSort] > b[currSort]) {
        return dir === "desc" ? 1 : -1;
      }
      return 0;
    });

    setDivProducts(sorted);
  };

  console.log("sort =====", sort);

  return (
    <>
      <Row className="align-items-center">
        <div style={{ display: "flex" }}>
          <div
            style={{
              padding: "10px 5px",
              border: "1px solid black",
              borderRadius: "3px",
              cursor: "pointer",
              margin: "5px",
            }}
            onClick={() => navigate("/admin/orderlist")}
          >
            Orders
          </div>
          <div
            style={{
              padding: "10px 5px",
              border: "1px solid black",
              borderRadius: "3px",
              cursor: "pointer",
              margin: "5px",
            }}
            onClick={() => navigate("/admin/productlist")}
          >
            Products
          </div>
          <div
            style={{
              padding: "10px 5px",
              border: "1px solid black",
              borderRadius: "3px",
              cursor: "pointer",
              margin: "5px",
            }}
            onClick={() => navigate("/admin/userlist")}
          >
            Users
          </div>
          <div
            style={{
              padding: "10px 5px",
              border: "1px solid black",
              borderRadius: "3px",
              cursor: "pointer",
              margin: "5px",
            }}
            onClick={() => navigate("/admin/setting")}
          >
            Settings
          </div>
        </div>
        <Col className="text-end">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Button
                className="my-3 mx-1"
                onClick={() => handleDivision("clothing")}
              >
                Clothing
              </Button>
              <Button
                className="my-3 mx-1"
                onClick={() => handleDivision("resin_art")}
              >
                Resin
              </Button>
            </div>
            <Button className="my-3" onClick={createProductHandler}>
              <FaPlus /> Create Product
            </Button>
          </div>
        </Col>
      </Row>

      {loadingCreate && <Loader />}
      {loadingDelete && <Loader />}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error.data.message}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const copy = Array.from(sort);
                    copy[0].dir = copy[0].dir === "asc" ? "desc" : "asc";
                    setSort(copy);
                    setCurrSort("name");
                    handleSort(copy[0].dir);
                  }}
                >
                  NAME{" "}
                  <span>
                    <img
                      style={{
                        width: "13px",
                        height: "13px",
                        transform:
                          sort[0].dir === "asc" ? "rotate(180deg)" : "",
                      }}
                      src={ArrowIcon}
                    />
                  </span>
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const copy = Array.from(sort);
                    copy[1].dir = copy[1].dir === "asc" ? "desc" : "asc";
                    setSort(copy);
                    setCurrSort("price");
                    handleSort(copy[1].dir);
                  }}
                >
                  PRICE{" "}
                  <span>
                    <img
                      style={{
                        width: "13px",
                        height: "13px",
                        transform:
                          sort[1].dir === "asc" ? "rotate(180deg)" : "",
                      }}
                      src={ArrowIcon}
                    />
                  </span>
                </th>
                <th
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const copy = Array.from(sort);
                    copy[2].dir = copy[2].dir === "asc" ? "desc" : "asc";
                    setSort(copy);
                    setCurrSort("stock");
                    handleSort(copy[2].dir);
                  }}
                >
                  STOCK{" "}
                  <span>
                    <img
                      style={{
                        width: "13px",
                        height: "13px",
                        transform:
                          sort[2].dir === "asc" ? "rotate(180deg)" : "",
                      }}
                      src={ArrowIcon}
                    />
                  </span>
                </th>
                <th>DIVISION</th>
                <th>CATEGORY</th>
                {currCategory !== "clothing" && <th>SUB-CATEGORY</th>}
                <th>BEST SELLER</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {divProducts?.map((product) => (
                <tr key={product._id}>
                  <td>{product._id}</td>
                  <td>{product.name}</td>
                  <td>${product.price}</td>
                  <td>{product.stock}</td>
                  <td>
                    {product.brand === "clothing" ? "Clothing" : "Resin Art"}
                  </td>
                  <td>{getCategory(product.brand, product.category)}</td>
                  {currCategory !== "clothing" && (
                    <td>{subcategory[product.subcategory] || "-"}</td>
                  )}
                  <td>{product.isBestSeller ? "Yes" : "No"}</td>
                  <td>
                    <LinkContainer to={`/admin/product/${product._id}/edit`}>
                      <Button variant="light" className="btn-sm mx-2">
                        <FaEdit />
                      </Button>
                    </LinkContainer>
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteHandler(product._id)}
                    >
                      <FaTrash style={{ color: "black" }} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate pages={data.pages} page={data.page} isAdmin={true} />
        </>
      )}
    </>
  );
};

export default ProductListScreen;
