import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Category.css";
import Rating from "./Rating";

const Category = ({ product }) => {
  console.log("product =====", product);
  return (
    <Card className="rounded">
      <Link to={`/shop?type=${product?.name}`} className="py-3 px-3">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="blurImage"
        >
          <img src={product?.img} style={{ width: "275px" }} variant="tops" />
        </div>
        <div className="imageText">{product?.name}</div>
      </Link>

      {/* <div style={{ textAlign: "center", padding: "5px" }}>
        <Link to={`/shop?type=${product.name}`}>
          <div></div>
        </Link>
      </div> */}
    </Card>
  );
};

export default Category;
