import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <Container>
        <Row>
          <Col className="text-center py-3">
            <p style={{ marginBottom: "0" }}>Fash Stash &copy;{currentYear} </p>
            <Link to="/privacy">Privacy Policy </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
