import React, { useEffect, useRef, useState } from "react";

import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";

import classes from "./Header.module.scss";
import Logo from "../assets/logo.svg";
import DownIcon from "../assets/down.png";
import FacebookIcon from "../assets/facebook.png";
import InstagramIcon from "../assets/instagram.png";
import CartIcon from "../assets/cart.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../slices/authSlice";
import { resetCart } from "../slices/cartSlice";

const pages = [
  { name: "Home", link: "/" },
  { name: "Clothing", link: "/clothing" },
  { name: "Resin Art", link: "/resin" },
  { name: "Best Sellers", link: "/shop?type=Best Sellers" },
  { name: "About Us", link: "/contact-us" },
  // { name: "About Us", link: "/" },
];

const useOuterClick = (callback) => {
  const callbackRef = useRef();
  const innerRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e) {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target)
      )
        callbackRef.current(e);
    }
  }, []);

  return innerRef;
};

const Header = ({ showSlider }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const [optionOpen, setOptionOpen] = useState(false);
  const [size, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  const navRef = useOuterClick(() => {
    const nav = document.getElementById("navbarSupportedContent");
    nav.classList.remove("show");
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };

  const loginClickHandler = () => {
    menuToggleHandler();
    userInfo ? navigate("/profile") : navigate("/login");
  };

  const cartClickHandler = () => {
    menuToggleHandler();
    navigate("/cart");
  };

  const logoutHandler = () => {
    localStorage.clear();
    dispatch(logout());
    dispatch(resetCart());
    navigate("/login");
  };

  return (
    <nav
      ref={navRef}
      id="navbar-item"
      style={{ top: showSlider ? "28px" : "0" }}
      className="navbar navbar-expand-lg navbar-dark bg-dark py-3 sticky-top"
    >
      <div className="container">
        <a className="navbar-brand fw-bold fs-4 px-2" href="/">
          <img src={Logo} />
        </a>
        <div style={{ display: "flex" }}>
          {window.innerWidth < 992 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <a a href="/cart">
                <img style={{ width: "25px" }} src={CartIcon} />
              </a>
            </div>
          )}
          <button
            className="navbar-toggler mx-2"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav m-auto my-2 text-center">
            {pages?.map((page) => (
              <li className="nav-item">
                <a
                  class="nav-link"
                  style={{ color: "white" }}
                  href={page.link}
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                >
                  {page.name}
                </a>
              </li>
            ))}
          </ul>

          <div className="buttons text-center">
            <a
              href="https://instagram.com/fashstash.by.drashti"
              target="_blank"
            >
              <img
                src={InstagramIcon}
                style={{ height: "40px", width: "40px", margin: "5px" }}
              />
            </a>
            <a href="https://www.facebook.com/fashstash13" target="_blank">
              <img
                src={FacebookIcon}
                style={{ height: "40px", width: "40px", margin: "5px" }}
              />
            </a>
          </div>

          <div className="buttons text-center">
            {userInfo?.isAdmin ? (
              <a href="/admin/orderlist" className="btn btn-outline-dark m-2">
                <i className="fa fa-cart-shopping mr-1"></i> Admin
              </a>
            ) : (
              <a href="/cart" className="btn btn-outline-dark m-2">
                <i className="fa fa-cart-shopping mr-1"></i> Cart
              </a>
            )}
            {userInfo ? (
              <>
                <a href="/profile" className="btn btn-outline-dark m-2">
                  <i className="fa fa-sign-in-alt mr-1"></i> My account
                </a>
                <div
                  onClick={() => logoutHandler()}
                  className="btn btn-outline-dark m-2"
                >
                  <i className="fa fa-cart-shopping mr-1"></i> Logout
                </div>
              </>
            ) : (
              <a href="/login" className="btn btn-outline-dark m-2">
                <i className="fa fa-sign-in-alt mr-1"></i> Login
              </a>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
