import React from "react";
import { Col, Row } from "react-bootstrap";
import Product from "../components/Product";
import { useGetProductsQuery } from "../slices/productsApiSlice";
import { Link, useParams, useSearchParams } from "react-router-dom";
import FilterIcon from "../assets/filter.png";
import ProductCarousel from "../components/ProductCarousel";
import CollectionItem from "../components/CollectionItem";

const itemMap = {
  Tops: { brand: "clothing", category: "tops" },
  Bralette: { brand: "clothing", category: "bralette" },
  Dresses: { brand: "clothing", category: "dresses" },
  Bottoms: { brand: "clothing", category: "bottoms" },
  "Cover Ups": { brand: "clothing", category: "covers" },
  "Home Decore": { brand: "resin_art", category: "homeDecore" },
  Accessories: { brand: "resin_art", category: "accessories" },
  "Kids Specials": { brand: "resin_art", category: "kids" },
};

const ShopScreen = () => {
  //   const { pageNumber, keyword, type } = useParams();
  const [params] = useSearchParams();
  console.log('params.get("type") =====', params.get("type"));
  const { data, isLoading, error } = useGetProductsQuery({
    brand:
      params.get("type") !== "Best Sellers"
        ? itemMap[params.get("type")].brand
        : undefined,
    category:
      params.get("type") !== "Best Sellers"
        ? itemMap[params.get("type")].category
        : undefined,
    isBestSeller: params.get("type") === "Best Sellers" ? true : undefined,
  });

  return (
    <>
      <Link style={{ color: "black" }} className="btn btn-light my-3" to={-1}>
        Go Back
      </Link>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {params.get("type") == "Best Sellers" && <ProductCarousel />}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span style={{ color: "black", fontSize: "32px", fontWeight: "600" }}>
            {params.get("type")}
          </span>
          {/* <span style={{ color: "black", cursor: "pointer", fontWeight: "600" }}>
          <img style={{ height: "20px", width: "20px" }} src={FilterIcon} />{" "}
          Filters
        </span> */}
        </div>
        <div>
          {/* <Row>
            {data?.products?.map((product) => (
              <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                <Product product={product} />
              </Col>
            ))}
          </Row> */}

          <div className="collection-page">
            <div className="items">
              {data?.products?.map((product, id) => (
                <CollectionItem kkey={id} item={product} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopScreen;
