import Slider from "react-slick";
import Message from "./Message";
import { useGetTopProductsQuery } from "../slices/productsApiSlice";
import "./Product.css";
import { useNavigate } from "react-router-dom";

const ProductCarousel = () => {
  const navigate = useNavigate();
  const { data: products, isLoading, error } = useGetTopProductsQuery(5);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
  };

  return isLoading ? null : error ? (
    <Message variant="danger">{error?.data?.message || error.error}</Message>
  ) : (
    <div>
      <Slider {...settings}>
        {products.map((product) => (
          <div className="containerDiv">
            <img
              style={{ cursor: "pointer" }}
              className="containerImg"
              src={product.image[0]}
              alt={product.name}
              fluid
              onClick={() => navigate(`/product/${product._id}`)}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductCarousel;
