import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
  Container,
} from "react-bootstrap";
import { toast } from "react-toastify";
import {
  useGetProductDetailsQuery,
  useCreateReviewMutation,
  useGetProductsQuery,
  useGetRelatedProductsQuery,
} from "../slices/productsApiSlice";
import Rating from "../components/Rating";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Meta from "../components/Meta";
import { addToCart } from "../slices/cartSlice";
import Slider from "react-slick";
import "../components/Product.css";
import "./ProductScreen.css";
import Product from "../components/Product";
import CollectionItem from "../components/CollectionItem";

const ProductScreen = () => {
  const { id: productId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(true);
  const [qty, setQty] = useState(1);
  const [selected, setSelected] = useState(null);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const addToCartHandler = () => {
    dispatch(addToCart({ ...product, qty, selected }));
    // navigate("/cart");
  };

  const {
    data: product,
    isLoading,
    refetch,
    error,
  } = useGetProductDetailsQuery(productId);

  console.log("product =====", product);

  const { userInfo } = useSelector((state) => state.auth);

  const [createReview, { isLoading: loadingProductReview }] =
    useCreateReviewMutation();
  const { data, refetch: refetchRelated } = useGetRelatedProductsQuery(
    JSON.stringify(product?.tags || [])
  );

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (product?.tags) {
      refetchRelated();
    }

    setSelected(
      product?.brand === "resin_art" ? product?.colors[0] : product?.sizes[0]
    );
  }, [product]);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      await createReview({
        productId,
        rating,
        comment,
      }).unwrap();
      refetch();
      toast.success("Review created successfully");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  console.log("product =====", product);

  return (
    <>
      <Link style={{ color: "black" }} className="btn btn-light my-3" to={-1}>
        Go Back
      </Link>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          {/* <div className={`popup ${show ? "show" : ""}`}>
            <div className="popup-content">
              <h2>Product added to cart!</h2>
              <p>
                Click <a href={"/"}>here</a> to view your cart.
              </p>
              <button onClick={() => null}>Close</button>
            </div>
          </div> */}
          <Meta title={product.name} description={product.description} />
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col md={6}>
              <Slider {...settings}>
                {product?.image?.map((img) => (
                  <div className="containerDiv">
                    <img
                      style={{ cursor: "pointer" }}
                      className="containerImg"
                      src={img}
                      alt={product.name}
                      fluid
                      onClick={() => window.open(img, "_blank")}
                    />
                  </div>
                ))}
              </Slider>
            </Col>

            <Col md={4} style={{ paddingTop: "50px" }}>
              <ListGroup.Item style={{ color: "black" }}>
                <h3>{product.name}</h3>
              </ListGroup.Item>
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col style={{ color: "black" }}>Price:</Col>
                      <Col>
                        <strong style={{ color: "black" }}>
                          ${product.price}
                        </strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  {/* <ListGroup.Item>
                    <Row>
                      <Col style={{ color: "black" }}>Status:</Col>
                      <Col style={{ color: "black" }}>
                        {product.stock > 0 ? "In Stock" : "Out Of Stock"}
                      </Col>
                    </Row>
                  </ListGroup.Item> */}

                  {/* Qty Select */}
                  {product.stock > 0 && (
                    <ListGroup.Item>
                      <Row>
                        <Col style={{ color: "black" }}>Quantity</Col>
                        <Col>
                          <Form.Control
                            as="select"
                            value={qty}
                            onChange={(e) => setQty(Number(e.target.value))}
                          >
                            {[...Array(product.stock).keys()].map((x) => (
                              <option key={x + 1} value={x + 1}>
                                {x + 1}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "5px" }}>
                        <Col style={{ color: "black" }}>
                          {product?.brand === "resin_art" ? "Color" : "Size"}
                        </Col>
                        <Col>
                          <Form.Control
                            as="select"
                            value={selected}
                            onChange={(e) => setSelected(e.target.value)}
                          >
                            {(product?.brand === "resin_art"
                              ? product?.colors
                              : product?.sizes
                            ).map((x, i) => (
                              <option key={i} value={x}>
                                {x}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      padding: "19px",
                    }}
                  >
                    {console.log(
                      'product?.brand === "resin_art" && product?.stock <= 0 =====',
                      product?.brand === "resin_art" && product?.stock <= 0
                    )}
                    {product?.brand === "resin_art" && product?.stock <= 0 ? (
                      "Available to order"
                    ) : (
                      <>
                        {" "}
                        <Button
                          // className="btn-block"
                          type="button"
                          style={{
                            backgroundColor: "black",
                            color: "white",
                          }}
                          disabled={product.stock <= 0}
                          onClick={addToCartHandler}
                        >
                          {product.stock <= 0 ? "Sold out" : "Add To Cart"}
                        </Button>
                        <Button
                          // className="btn-block"
                          type="button"
                          style={{
                            backgroundColor: "black",
                            color: "white",
                          }}
                          onClick={() =>
                            navigator.share({
                              url: window.location.href,
                              title: product.name,
                            })
                          }
                        >
                          Share
                        </Button>
                      </>
                    )}
                  </div>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <div style={{ marginTop: "50px" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: product?.description,
              }}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            {data?.products?.length > 0 && (
              <>
                <h3 style={{ color: "black" }}>Related Products</h3>
                {/* <Container>
                  <Row>
                    {data?.products.map((product) => {
                      if (product._id === productId) return;
                      return (
                        <>
                          <Col>
                            <Product product={product} />
                          </Col>
                        </>
                      );
                    })}
                  </Row> */}
                {/* <Paginate
            pages={data.pages}
            page={data.page}
            keyword={keyword ? keyword : ''}
          /> */}
                {/* </Container> */}

                <div className="collection-page">
                  <div className="items">
                    {data?.products.map((item, id) => {
                      if (product?._id === item?._id) return;
                      return <CollectionItem key={id} item={item} />;
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
          {/* <Row className='review'>
            <Col md={6}>
              <h2>Reviews</h2>
              {product.reviews.length === 0 && <Message>No Reviews</Message>}
              <ListGroup variant='flush'>
                {product.reviews.map((review) => (
                  <ListGroup.Item key={review._id}>
                    <strong>{review.name}</strong>
                    <Rating value={review.rating} />
                    <p>{review.createdAt.substring(0, 10)}</p>
                    <p>{review.comment}</p>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item>
                  <h2>Write a Customer Review</h2>

                  {loadingProductReview && <Loader />}

                  {userInfo ? (
                    <Form onSubmit={submitHandler}>
                      <Form.Group className='my-2' controlId='rating'>
                        <Form.Label>Rating</Form.Label>
                        <Form.Control
                          as='select'
                          required
                          value={rating}
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option value=''>Select...</option>
                          <option value='1'>1 - Poor</option>
                          <option value='2'>2 - Fair</option>
                          <option value='3'>3 - Good</option>
                          <option value='4'>4 - Very Good</option>
                          <option value='5'>5 - Excellent</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group className='my-2' controlId='comment'>
                        <Form.Label>Comment</Form.Label>
                        <Form.Control
                          as='textarea'
                          row='3'
                          required
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Button
                        disabled={loadingProductReview}
                        type='submit'
                        variant='primary'
                      >
                        Submit
                      </Button>
                    </Form>
                  ) : (
                    <Message>
                      Please <Link to='/login'>sign in</Link> to write a review
                    </Message>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row> */}
        </>
      )}
    </>
  );
};

export default ProductScreen;
