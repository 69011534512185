import React from "react";

const PrivacyScreen = () => {
  return (
    <>
      <div id="main" class="wrapper">
        <div class="breadcrumb-title-wrapper breadcrumb-v1">
          <div class="breadcrumb-content container">
            <div class="breadcrumb-title">
              <h1 class="heading-title page-title entry-title ">
                Privacy Policy
              </h1>
            </div>
          </div>
        </div>

        <div class="page-container show_breadcrumb_v1">
          <div id="main-content" class="ts-col-24">
            <div id="primary" class="site-content">
              <article
                id="post-3"
                class="post-3 page type-page status-publish hentry"
              >
                <p>
                  We value our consumer’s trust placed in our brand. We insist
                  upon the highest standards for secure transactions and
                  customer information privacy. Do read the following statements
                  to learn about our information gathering &amp; practices.
                </p>

                <p>
                  Note: Our privacy policy is subject to change from time to
                  time without notice. To make sure you are aware of any
                  changes, please review this policy periodically.
                </p>

                <p>
                  <strong>
                    1. Collection of Information – Personal &amp; Identifiable
                  </strong>
                </p>

                <p>
                  We store collected personally identifiable information (email
                  address, name, phone number, etc.) from our registered
                  customers when a free account is set up with colorstory.in.
                  While one can browse through our site without the registration
                  process, for the purpose of placing an order, one has to
                  complete the registration process by filling all the valid
                  information.
                </p>

                <p>
                  We do use your contact information to send you intimation of
                  new products &amp; offers from time to time based on your
                  previous orders placed &amp; your interests.
                </p>

                <p>
                  <strong>2. Use of Demographic and Profile Data</strong>
                </p>

                <p>
                  The personal information provided is used for fulfilling the
                  services request placed by customers. To the extent we use
                  your personal information to market our offers and new product
                  launches, there is an option provided to opt-out of
                  intimations. The information will also be used to resolve
                  disputes; troubleshoot problems; promoting safe service;
                  collect fee (owed- billing); measure interests of our products
                  &amp; services, Customize shopping / browsing experience;
                  protect us against erroneous / fraudulent transactions, and
                  other criminal activities, enforce the terms and conditions
                  for shopping / use-age of the webpage and as described to you
                  at the time of collection of such relevant information.
                </p>

                <p>
                  We continually aim to improve our service and gather
                  suggestions to improve our range offered, the data collected
                  is also used to analyze demographic and profile data about our
                  users and their activity on our website.
                </p>

                <p>
                  We identify and use your IP address to help diagnose problems,
                  and to administer our website/ server from time to time. Your
                  IP address is used to identify you &amp; to gather broad
                  demographic information.
                </p>

                <p>
                  We may indulge you in taking up &amp; completing optional
                  online surveys, in which, the consumer’s information and
                  demographic information (like zip code, age, or income level)
                  will be asked. We use this data to tailor your online
                  experience, providing you with content that we think you might
                  be interested in &amp; we would endeavor to display content
                  according to your shopping and browsing preferences.
                </p>

                <p>
                  <strong>3. Cookies</strong>
                </p>

                <p>
                  A “cookie” is a small piece of information stored by a server
                  on a browser, so, it can be later read back (on being
                  required). They are useful for enabling browsers to remember
                  information specific to any given user. itsybitsy.in places
                  both permanent and temporary cookies in your computer’s hard
                  drive. colorstory.in’s cookies do not contain any of your
                  personally identifiable information.
                </p>

                <p>
                  <strong>4. Personal Information Use &amp; Sharing</strong>
                </p>

                <p>
                  We may choose to part with personal information to other
                  corporate entities &amp; affiliates to: help detect / prevent
                  identity theft, fraud / other potentially illegal acts;
                  correlate related or multiple accounts to prevent abuse of our
                  services. This may also be used to facilitate joint or
                  co-branded services that you request. Wherein, such services
                  are provided by more than one corporate entity. Those entities
                  &amp; affiliates may not market to our customer as a result of
                  such sharing unless it’s explicitly opted-in for.
                </p>

                <p>
                  Personal information may be disclosed, if required to do so,
                  by law or in the good faith that such disclosure is reasonably
                  necessary to respond to subpoenas, court orders, or other
                  legal processes and proceedings. We may disclose personal
                  information to law enforcement offices, third party rights
                  owners, or others in the good faith belief that such
                  disclosure is reasonably necessary to: enforce our Terms or
                  Privacy Policy; respond to claims that an advertisement,
                  posting or other content violates the rights of a third party;
                  or protect the rights, property or personal safety of our
                  users or the general public.
                </p>

                <p>
                  colorstory.in and its affiliates will share some or all of
                  your personal information with another business entities,
                  should we (or our associates) plan to merge with, or be
                  acquired by that business entity. On occurrences of such
                  transactions, the other business entity (or the new combined
                  entity) will be required to follow this privacy policy with
                  respect to your personal information.
                </p>

                <p>
                  <strong>5. Security Measures</strong>
                </p>

                <p>
                  We attempt to all times have stringent security measures to
                  protect the loss, misuse, &amp; alteration of our customers
                  information under our control. Whenever a customer changes or
                  access their account or their information, we offer the use of
                  a secure server. Once your information is in our possession we
                  adhere to strict security guidelines, protecting it against
                  unauthorized access.
                </p>

                <p>
                  <strong>6. Advertisements on itsybitsy.in</strong>
                </p>

                <p>
                  We may use third-party advertising companies to serve ads and
                  this will be viewed by our customers on browsing / shopping at
                  our website. These companies may use information (not
                  including your name, address, email address, or telephone
                  number) about your visits to this and other websites in order
                  to provide advertisements about goods and services of interest
                  to you.
                </p>

                <p>
                  <strong>7. Queries / Questions?</strong>
                </p>

                <p>
                  Any Queries / Questions regarding this statement should be
                  directed to the following address:&nbsp;
                  <strong>
                    <a href="mailto:fashstash13@gmail.com">
                      fashstash13@gmail.com
                    </a>
                    .
                  </strong>
                </p>

                <p>
                  Orders – Also note, if the payment is not received within 3
                  days of placing an order it will be automatically cancelled.
                </p>
              </article>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyScreen;
